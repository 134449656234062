exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-lavora-con-noi-strapi-jobposition-slug-tsx": () => import("./../../../src/pages/lavora-con-noi/{StrapiJobposition.slug}.tsx" /* webpackChunkName: "component---src-pages-lavora-con-noi-strapi-jobposition-slug-tsx" */),
  "component---src-pages-media-comunicati-stampa-strapi-press-release-slug-tsx": () => import("./../../../src/pages/media/comunicati-stampa/{StrapiPressRelease.slug}.tsx" /* webpackChunkName: "component---src-pages-media-comunicati-stampa-strapi-press-release-slug-tsx" */),
  "component---src-pages-media-news-ed-eventi-strapi-event-slug-tsx": () => import("./../../../src/pages/media/news-ed-eventi/{StrapiEvent.slug}.tsx" /* webpackChunkName: "component---src-pages-media-news-ed-eventi-strapi-event-slug-tsx" */),
  "component---src-pages-media-news-ed-eventi-strapi-post-slug-tsx": () => import("./../../../src/pages/media/news-ed-eventi/{StrapiPost.slug}.tsx" /* webpackChunkName: "component---src-pages-media-news-ed-eventi-strapi-post-slug-tsx" */),
  "component---src-pages-media-newsletter-outer-space-strapi-newsletter-slug-tsx": () => import("./../../../src/pages/media/newsletter-outer-space/{StrapiNewsletter.slug}.tsx" /* webpackChunkName: "component---src-pages-media-newsletter-outer-space-strapi-newsletter-slug-tsx" */),
  "component---src-pages-opportunita-fondo-innovazione-comunicati-strapi-innovation-announcement-slug-tsx": () => import("./../../../src/pages/opportunita/fondo-innovazione/comunicati/{StrapiInnovationAnnouncement.slug}.tsx" /* webpackChunkName: "component---src-pages-opportunita-fondo-innovazione-comunicati-strapi-innovation-announcement-slug-tsx" */),
  "component---src-pages-prodotti-e-servizi-strapi-project-slug-tsx": () => import("./../../../src/pages/prodotti-e-servizi/{StrapiProject.slug}.tsx" /* webpackChunkName: "component---src-pages-prodotti-e-servizi-strapi-project-slug-tsx" */),
  "component---src-pages-strapi-page-permalink-tsx": () => import("./../../../src/pages/{StrapiPage.permalink}.tsx" /* webpackChunkName: "component---src-pages-strapi-page-permalink-tsx" */)
}

